import React from "react"

interface Props extends React.SVGProps<SVGSVGElement> {}

export const IconGitHub: React.FC<Props> = props => (
  <svg width="1em" height="1em" viewBox="0 0 27 27" {...props}>
    <path
      d="M13.5 0C6.041 0 0 6.194 0 13.841c0 6.125 3.864 11.299 9.23 13.133.676.12.929-.295.929-.658 0-.329-.017-1.419-.017-2.578-3.392.64-4.27-.848-4.54-1.626-.151-.398-.81-1.627-1.383-1.955-.473-.26-1.148-.9-.017-.917 1.063-.018 1.822 1.003 2.075 1.418 1.215 2.094 3.156 1.506 3.932 1.142.119-.9.473-1.505.861-1.851-3.004-.346-6.143-1.54-6.143-6.834 0-1.505.524-2.751 1.384-3.72-.135-.346-.607-1.765.135-3.668 0 0 1.13-.363 3.713 1.419a12.238 12.238 0 013.375-.467c1.147 0 2.295.155 3.375.467 2.582-1.8 3.712-1.42 3.712-1.42.743 1.904.27 3.323.135 3.669.86.969 1.384 2.197 1.384 3.72 0 5.311-3.156 6.488-6.16 6.834.49.433.912 1.263.912 2.56 0 1.852-.017 3.34-.017 3.807 0 .363.253.796.928.658C23.136 25.14 27 19.949 27 13.84 27 6.194 20.959 0 13.5 0z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
)

export const IconLinkedIn: React.FC<Props> = props => (
  <svg width="1em" height="1em" viewBox="0 0 27 27" {...props}>
    <path
      d="M21.375 0H5.625A5.626 5.626 0 000 5.625v15.75A5.626 5.626 0 005.625 27h15.75A5.625 5.625 0 0027 21.375V5.625A5.625 5.625 0 0021.375 0zM9 21.375H5.625V9H9v12.375zM7.312 7.573A1.977 1.977 0 015.345 5.59c0-1.096.882-1.985 1.968-1.985 1.087 0 1.97.89 1.97 1.985 0 1.096-.882 1.984-1.97 1.984zM22.5 21.375h-3.375V15.07c0-3.789-4.5-3.502-4.5 0v6.305H11.25V9h3.375v1.986c1.57-2.91 7.875-3.125 7.875 2.785v7.604z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
)

export const IconTwitter: React.FC<Props> = props => (
  <svg width="1em" height="1em" viewBox="0 0 33 27" {...props}>
    <path
      d="M10.378 27c12.454 0 19.264-10.387 19.264-19.393 0-.295-.006-.59-.02-.881A13.82 13.82 0 0033 3.196a13.43 13.43 0 01-3.888 1.073A6.832 6.832 0 0032.089.5a13.523 13.523 0 01-4.3 1.654A6.741 6.741 0 0022.849 0c-3.74 0-6.771 3.052-6.771 6.815 0 .535.06 1.055.175 1.554-5.627-.285-10.617-2.997-13.956-7.121a6.833 6.833 0 00-.916 3.426 6.823 6.823 0 003.013 5.674 6.687 6.687 0 01-3.067-.853v.087c0 3.301 2.333 6.057 5.431 6.682a6.737 6.737 0 01-3.058.117c.862 2.708 3.362 4.679 6.325 4.734a13.525 13.525 0 01-8.409 2.918C1.069 24.033.53 24 0 23.938A19.072 19.072 0 0010.379 27"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
)
